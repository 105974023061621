import React, { useState, useEffect } from "react";
import TextCard from "../Components/TextCard";
import soft_dev from "../Resources/soft_dev.png"
import PageInfo from "../Components/PageInfo";

function SoftDev() {
  const [marginTop, setMarginTop] = useState("0");
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setMarginTop("6rem");
    } else {
      setMarginTop("0");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="mt-6r">
      <PageInfo title={"Home / Software Development"}/>
      <TextCard
        font={"TN"}
        img={soft_dev}
        heading={"Maars: Your Gateway to Innovative Software Development Services"}
        text={`In the dynamic landscape of the digital era, businesses are constantly seeking innovative solutions to thrive and stay ahead of the competition. Maars, a prominent name in the realm of software development services, offers a diverse range of cutting-edge solutions to meet the evolving needs of businesses across the globe.<br/><br/>

        <b>Our Company:</b><br/>
        Maars, with a reputation for excellence and a commitment to pushing the boundaries of technology, has established itself as a trusted partner for businesses of all sizes. With a team of skilled and experienced professionals, we are dedicated to providing customized software development services that empower our clients to achieve their goals.<br/><br/>
        
        <b>Our Services:</b><br/>
        <br/>
        Custom Software Development:</b> Maars specializes in creating tailor-made software solutions that align with your specific business objectives. We meticulously design, develop, and implement software that streamlines processes, enhances productivity, and boosts your bottom line.
        <br/>
        Web Application Development:</b> In an age where web presence is paramount, Maars excels in crafting web applications that are not only visually stunning but also functionally robust. Our web solutions offer seamless user experiences, resulting in increased engagement and conversion rates.
        <br/>
        <b>Cloud Solutions:</b> Harness the power of the cloud with Maars' expertise in cloud computing and cloud-based software. We provide scalable, secure, and cost-effective solutions that enable seamless data management and accessibility.
        <br/>
        <b>Maintenance and Support:</b> Beyond development, we provide ongoing maintenance and support to ensure your software remains up-to-date and trouble-free. Our dedicated support team is always ready to assist you in case of any issues.
        <br/><br/>
        <b>Why Choose Maars:</b>
        <br/><br/>
        <b>Expertise:</b> Our team of skilled developers and tech experts has an in-depth understanding of the latest technologies and industry best practices.<br/>
        <b>Customization:</b> We understand that every business is unique, and we tailor our services to match your specific requirements.<br/>
        Quality Assurance: Maars is committed to delivering high-quality, error-free software solutions that exceed your expectations.<br/>
        Timely Delivery: We value your time, and our projects are executed with precision and delivered on schedule.<br/>
        Partner with Maars today and embark on a journey to technological innovation and business growth. We are your gateway to reliable and innovative software development services that will propel your business to new heights.`}
        imgSide={"right"}
        roundedCorner={true}
      />
    </div>
  );
}

export default SoftDev;
