import React from "react";
import PageInfo from "./PageInfo";

function TextCard({ heading, text, img, imgSide, roundedCorner, last, font, noHr }) {
  const createMarkup = (html) => {
    return { __html: html };
  };

  return (
    <div>
      <div>
        <div className={`section ${roundedCorner ? "align-flex-start" : ""}`}>
          {imgSide === "left" && img ? (
            <div className="thumbnail">
              <img src={img} alt="image here" className="imgFluid" />
            </div>
          ) : null}
          <div className={`${imgSide === "left" ? "paras-left" : "paras"}`}>
            <p
              className={`sectionTag text-big ${
                roundedCorner ? "sectionTagMain" : ""
              } `}
            >
              {heading}
            </p>
            <div
              className={`sectionSubTag text-small ${font=="TN"?"font-tiltN":"font-poppins"}`}
              dangerouslySetInnerHTML={createMarkup(text)}
            />
          </div>
          {imgSide === "right" && img ? (
            <div className="thumbnail">
              <img
                src={img}
                alt="image here"
                className={`imgFluid ${roundedCorner ? "rounded-circle" : ""} ${roundedCorner ? "w-400px":""}`}
              />
            </div>
          ) : null}
        </div>
      </div>
      {roundedCorner && !noHr || last ? (
        ""
      ) : (
        <hr
          style={{
            borderTop: "1px solid black",
            width: "80vw",
            margin: "auto",
          }}
          className="pt-3 mt-3 pb-3"
        />
      )}
    </div>
  );
}

export default TextCard;
