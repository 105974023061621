import React from "react";

function PageInfo({ title }) {
  return (
    <div className="mt-4r page-info">
      <div className="d-flex flex-column">
        <div className="font-poppins">{title}</div>
      </div>
    </div>
  );
}

export default PageInfo;
