import React, { useEffect } from "react";
import PageInfo from "../Components/PageInfo";
import logo from "../Resources/logo.png";
import mail from "../Resources/mail.png";
import mail_logo from "../Resources/mail_logo.png";
import globe_logo from "../Resources/globe.png";
import contact from "../Resources/contact.jpeg";

function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="mt-6r">
      <PageInfo title={"Home / Contact Us"} />
      <div className="d-flex flex-column">
        <div className="map-cont">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.8444779975607!2d80.99017562601271!3d26.781226965727694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399be4ae6c8c32f7%3A0xcee872f35d08876a!2sGolf%20City%2C%20Sector%20B%20Ansal%20API%2C%20Lucknow%2C%20Muzaffar%20Nagar%20Ghusval%2C%20Uttar%20Pradesh%20226030!5e0!3m2!1sen!2sin!4v1697733873777!5m2!1sen!2sin"
            className="map"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="details-container d-flex justify-content-evenly">
          <div className="d-flex flex-column justify-content-between">
            <div className="details-box">
              <div className="fw-bold color-black">Address:</div>
              <div>Second Floor, 28, Sector-C,</div>
              <div>Pocket-4, Sushant Golf City,</div>
              <div>Lucknow, U.P. 226030</div>
              <div>India.</div>
            </div>
            <div className="details-box">
              <div className="fw-bold color-black">Work Hours:</div>
              <div>Mon-Fri 08:00 AM - 05:00 PM</div>
              <div>Sat-Sun: Emergency only</div>
            </div>
            <div className="details-box">
              <div className="fw-bold color-black">Contact:</div>
              <div>
                <p className="d-flex align-items-center mt-3">
                  <img src={mail_logo} className="contact-icon" />
                  <img src={mail} className="contact-icon" />
                </p>
              </div>
              <div>
                <p className="d-flex align-items-center">
                  <img src={globe_logo} className="contact-icon pr-1" />
                  <div className="mx-2">www.themaars.org</div>
                </p>
              </div>
            </div>
          </div>
          <img src={contact} className="contact-img" />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
