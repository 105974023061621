import React, { useEffect } from "react";
import PageInfo from "../../Components/PageInfo";

function TermsOfUse() {
  useEffect(()=>{
    window.scrollTo(0,0)
  }, [])
  return (
    <div className="mt-6r">
      <PageInfo title={"Home / Terms & Conditions"} />
      <div className="w-80p m-auto mt-4">
        <h2 className="font-poppins fw-bold">Terms Of Use</h2>
        <h3 className="font-poppins fw-bold mt-4 mb-4">
          GENERAL TERMS OF USE & SERVICE
        </h3>
        <div className="font-poppins">
          The website "www.themaars.org" (the "Site") is published and
          maintained by "Maars Consultancy Services" (namely MCS), a
          company incorporated and existing in accordance with the laws of India
          When you access, browse or use this Site, you accept, without
          limitation or qualification, the terms and conditions set forth
          herein. When you access any of the sub-Site (whether belonging to an
          'associate' of Company or otherwise) through this Site, then such
          sub-Site may have its own terms and conditions, which are specific to
          such sub-Site.
        </div>
        <div className="font-poppins">
          <br />
          These Terms and Conditions of use and any additional terms posted on
          this Site together constitute the entire agreement between Company and
          you with respect to your use of this Site.
        </div>
        <h4 className="my-4">SITE AND ITS CONTENTS</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> This Site is only for
            your personal use. You shall not distribute exchange, modify, sell
            or transmit anything you copy from this Site, including but not
            limited to any text, images, audio and video, for any business,
            commercial or public purpose.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> As long as you comply
            with the terms of these Terms and Conditions of Use, Company grants
            you a non-exclusive, non-transferable, limited right to enter, view
            and use this Site. You agree not to interrupt or attempt to
            interrupt the operation of this Site in any manner whatsoever.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Access to certain
            areas of the Site may only be available to registered members. To
            become a registered member, you may be required to answer certain
            questions or provide certain details. Answers to such questions or
            details required may be mandatory and/or optional. You represent and
            warrant that all information you supply to us, about yourself, and
            others, is true and accurate.
          </li>
        </ul>
        <h4 className="my-4">LINKS TO THIRD-PARTY WEBSITES</h4>
        <div className="font-poppins">
          This Site may contain links to websites operated by parties other than
          Company. Company does not control such Sites and is not responsible
          for their contents. Company's inclusion of hyperlinks to such Sites
          does not imply any endorsement of the material on such Sites or any
          association with their operators. If you decide to access any of the
          third party Sites linked to this Site, you do so entirely at your own
          risk.
        </div>
        <h4 className="my-4">OWNERSHIP</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> All
            materials/products/services on this Site, including but not limited
            to audio, images, software, text, icons and such like (the
            "Content"), are protected by copyright under international
            conventions and copyright laws. You cannot use the Content for any
            purpose, except as specified herein.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You agree to follow
            all instructions so provided on this Site limiting the way you may
            use the Content.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> There are a number of
            logos, service marks and trademarks found on this Site owned by
            MCS. By displaying them on this Site, Company is not granting you
            any license to utilize those logos, service marks, or trademarks.
            Any unauthorized use of the Content may violate copyright laws,
            trademark laws, the laws of privacy and publicity, and civil and
            criminal statutes.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You may download such
            copy/copies of the Content to be used only by you for your personal
            use.
          </li>
        </ul>
        <h4 className="my-4">MCS RIGHTS</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> If you send any
            communications or materials to the Site by electronic mail or
            otherwise, including any comments, data, questions, suggestions or
            the like, all such communications are, and will be treated by
            Company, as non-confidential.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You hereby give up
            any and all claim that any use of such material violates any of your
            rights including moral rights, privacy rights, proprietary or other
            property rights, publicity rights, rights to credit for material or
            ideas, or any other right, including the right to approve the Way
            Company uses such material.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Any material
            submitted to this Site may be adapted, broadcast, changed, copied,
            disclosed, licensed, performed, posted, published, sold, transmitted
            or used by Company anywhere in the world, in any medium, forever.
          </li>
        </ul>
        <h4 className="my-4">TRANSMITTED MATERIAL</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Internet
            transmissions are never completely private or secure. You understand
            that any message or information you send to this Site may be read or
            intercepted by others unless there is a special notice that a
            particular message (for example, credit card/debit card/bank
            information) is encrypted (send in code). Sending a message to
            Company does not cause Company to have any special responsibility to
            you.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The copyright in the
            contents of this Site belongs to Company. Accordingly, Company
            reserves all rights. Copying of part or all the contents of this
            Site without permission of Company is prohibited except to the
            extent that such copying/printing is necessary for the purposes of
            availing of the services.
          </li>
        </ul>
        <h4 className="my-4">DISCLAIMER</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The material in this
            Site could include technical inaccuracies or typographical errors.
            Company may make changes/modifications or improvements on the Site
            at any time.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The materials on this
            Site are provided on an "As Is" basis, without warranties of any
            kind either expressed or implied. To the fullest extent permissible
            pursuant to applicable law, Company disclaims all warranties of
            merchantability and fitness for a particular purpose.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company does not
            warrant that the functions contained in this Site will be
            uninterrupted or error free, that defects will be corrected, or that
            this Site or the servers that make it available are free of viruses
            or other harmful components, but shall endeavour to ensure your
            fullest satisfaction. Company does not warrant or make any
            representations regarding the use of or the result of the use of the
            material on the Site in terms of their correctness, accuracy and
            reliability.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You acknowledge that
            this Site is provided only on the basis set out in these terms and
            conditions. Your uninterrupted access or use of this Site on this
            basis may be prevented by certain factors outside our reasonable
            control including, without limitation, the unavailability,
            inoperability or interruption of the Internet or other
            telecommunications services or as a result of any maintenance or
            other service work carried out on this Website. Company does not
            accept any responsibility and will not be liable for any loss or
            damage whatsoever arising out of or in connection with any
            ability/inability to access or to use the Site.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company will not be
            liable to you or to any other person for any direct, indirect,
            incidental, punitive or consequential loss, damage, cost or expense
            of any kind whatsoever and howsoever caused from out of your usage
            of this Site.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Any resemblance of
            any content of the website or portal to any other website and portal
            is merely a coincident, no such claim will be entertained by any
            means.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b>
            <b>
              If for any reason, law does not permit exclusions of liability
              then, the liability of the Company shall be limited to such amount
              paid by the user and retained by the Company for the transaction
              in question.
            </b>
          </li>
        </ul>
        <h4 className="my-4">GENERAL PROVISIONS</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company may add to,
            modify or remove any part of these Terms and Conditions of Use at
            any time as it may deem fit, without notice. Any changes to these
            Terms and Conditions of Use or any terms posted on this Site apply
            as soon as they are posted. By continuing to use this Site after any
            changes are posted, you are indicating your acceptance of those
            changes.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company may add,
            change, discontinue, remove or suspend any other Content posted on
            this Site, including features and specifications of products
            described or depicted on the Site, temporarily or permanently, at
            any time, without notice and without liability.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company reserves the
            right to undertake all necessary steps to ensure that the security,
            safety and integrity of Company's systems as well as its clients and
            users interests are and remain, well-protected. Towards this end,
            Company may take various steps to verify and confirm the
            authenticity, enforceability and validity of orders placed by you.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> If Company, in its
            sole and exclusive discretion, concludes that the said orders are
            not or do not reasonably appear to be, authentic, enforceable or
            valid, then Company may cancel the said orders at any time.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company reserves its
            exclusive right in its sole discretion to alter, limit or
            discontinue the Site or any material posted herein, in any respect.
            Company shall have no obligation to take the needs of any User into
            consideration in connection therewith.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Company reserves its
            right to deny in its sole discretion any user access to this Site or
            any portion hereof without notice.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> No waiver by Company
            of any provision of these Terms and Conditions shall be binding
            except as set forth in writing and signed by its duly authorized
            representative.
          </li>
        </ul>
        <h4 className="my-4">PROMOTION CODES</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> MCS generates
            promotion codes from time to time which may be availed on the Site
            as a discount coupon. Users are advised that the promotional offer
            of using Promotion Codes for receiving discounts can be availed only
            if correct and full details (of the promotional code) are filled in
            at the time of purchase. In case if the customer fails to enter the
            promo code before purchase, the company shall not be liable to give
            any credit to the customer after the purchase has been made.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Considering there are
            multiple promotional offers running on the site, user can avail the
            benefit of only one offer at a time. No two promotional codes can be
            combined at the time of purchase.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Promotional discounts
            are applied only on the base amount of items. Discounts are not
            applicable for any additional components including taxes.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> On cancellation of
            purchase done using promotional codes, the charges are calculated on
            total price.
          </li>
        </ul>
        <h4 className="my-4">DISPUTES</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> If any dispute arises between you and Company during your use of the Site or thereafter, in connection with and arising from your use or attempt to use this Site, the dispute shall be referred to arbitration. Both parties shall agree to a sole arbitrator. The place of arbitration shall be Delhi. The arbitration proceedings shall be in the English language.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The said arbitration proceedings shall be governed and construed in accordance with the Arbitration and Conciliation Act, 1996 and modifications thereof as in force at the relevant time.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> These terms and conditions are governed by and shall be construed in accordance with the laws of the Republic of India and any dispute shall exclusively be subject to the jurisdiction of the appropriate Courts situated at Delhi, India.
          </li>
        </ul>
        <h2 className="font-poppins fw-bold">TERMS FOR ONLINE ADMISSION</h2>
        <h3 className="font-poppins fw-bold my-4">
        CANDIDATES ARE REQUIRED TO FURNISH THE FOLLOWING AT THE TIME OF ATTENDING THE CLASS:
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> A copy of the printed invoice (A print out of the invoice or the print out of the invoice e-mail)
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Identity proof (Driving license, Student ID card, Company ID card, Passport, PAN card or Voter ID card). Failing to do so, they may not be allowed to attend the class.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> All admission booking issued shall be non-transferable.
          </li>
        </ul>
        <h4 className="my-4">CHANGE IN BATCH TIMINGS:</h4>
        <div className="font-poppins">In case the selected branch changes the time of batch at the time of attending classes due to some reason or any other discrepancy, we will refund the charged amount only after the verification from concerned branch.(condition to change the batch timing at the time of attending class in branch by the request of candidate is the matter of request approval by the concerned branch authority)</div>
        <h4 className="my-4">ADMISSION CANCELLATION:</h4>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> <b>Cancellation of admission can be claimed only within 24hours from the time of admission on My Shop by calling on the customer care number toll free number (7052477777) or to cancel the procedure:</b>
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Log onto www.themaars.org.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Click on contact us.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Find the official mail id "info@themaars.org".
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Mail us your cancellation request.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You need to enter invoice serial no/order no. mobile no, customer id.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> <b>Partial cancellation of admission fee is not available.</b>
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> <b>Reschedule of selected batch is possible after request approval.</b>
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> <b>Refunds on purchase cancelled will be directly transferred to the respective account from which payment was made at the time of booking.</b>
          </li>
        </ul>
        <h2 className="font-poppins fw-bold">SPEED TEST CARD/STUDY MATERIAL/E-MICA/E-NEWS SUBSCRIPTION TERMS</h2>
        <h3 className="font-poppins fw-bold my-4">
        GENERAL TERMS AND CONDITIONS:
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> OTP once generated cannot be changed at any stage of process.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Services mentioned under the specific card is provisional and can be changed at later stage.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Test contains the subject of respective examination pattern only, no claim will be considered for any individual.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Language of test will be of as actual examination only.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Validity of test card will be of as mentioned at the time of purchase.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Content of study material is try to made error free but in case of any printing mistake, no claim will be entertained.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Decision of MCS in respect of benefits or any matters related thereto shall be final and binding and no correspondence will be entertained in this behalf.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> All disputes are subject to exclusive jurisdiction of the competent courts in New Delhi only.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> By participating in this Scheme, Customers agree to be bound by these terms and conditions and waive any right to claim ambiguity in these terms & conditions. All other standard terms and conditions applicable are listed on "www.themaars.org.
          </li>
        </ul>
        <h3 className="font-poppins my-4">
        CANCELLATION POLICY:
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company offers FREE CANCELLATION of study material only before the shipment. FREE CANCELLATION is regulated by certain terms and conditions.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Cancellations can be done online as well through our call-centre.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Only those cancellation requests which are made either online or on the phone to our customer support team shall be entertained. The Company shall not be liable to entertain any cancellation requests made through any other medium.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Purchase done under offers are not refundable.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> No cancellation possible in speed test card/e-mica subscription/e-news subscription (due to generation of OTP.)
          </li>
        </ul>
        <h3 className="font-poppins my-4">
        MODIFICATIONS & REFUNDS
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company doesn't support changes and modifications to online bookings once they're made
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> All the refunds shall be done by the MCS directly to you and the Company is not responsible to ensuring any refunds whatsoever.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Your bank may debit its own separate charges from refunds made to your credit card or bank account.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> If you have received an item (BOOKS/STUDY MATERIAL) in a 'Damaged' or 'Defective' condition, you may request a replacement within 2 days of receiving product at no extra cost. Return Policy is specific. Please check the respective return policy when buying or before initiating a return for a replacement.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Replacement is subject to availability of stock. If the product is out of stock, you will receive a full refund, no questions asked.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> No REFUND possible in speed test card (due to generation of OTP.)
          </li>
        </ul>
        <h5 className="font-poppins my-4">FURTHER SPECIFIC TERMS AND CONDITIONS GOVERNING THE USE OF CONTACT FORUMS AND MESSAGING VIA THE PRIVATE MAILING SYSTEM
        </h5>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You agree that where you contact us by using the Website, its private message system and/or a contact forum, you will not write or send or talk any content which:
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Is abusive, threatening, offensive, defamatory, coercive, obscene, belligerent, glorifying violence, vulgar, sexually explicit, pornographic or otherwise objectionable and/or actionable/illicit;
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Is contrary to any applicable law, especially violates third parties' intellectual property rights;
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Is commercial advertisement or any other undesired advertisement (e.g. spam), and
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Is otherwise in breach of any other part of these terms and conditions of use.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company retains the right to temporarily or permanently ban any user/s who are in breach of these or any other part of these terms and conditions.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Website and content may be used it at your own risk.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company expressly reserves the right to block, modify, add to and/or delete all or part of the Website, or any of its other websites, whether temporarily or permanently.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You are responsible for ensuring that you have the necessary IT equipment to use the Website.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company does not guarantee or warrant that the Website is free of viruses, worms, Trojan horses or any other malicious codes which could have a destructive, vitiating, disruptive or other negative impact.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> MCS is the owner or the licensee of all intellectual property rights (copyright, trademarks, patents, design rights etc.) in our Website, and in the content published on it.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Any content published on the Website (e.g. software, products, trademarks such as logos etc., information, reports, pictures and graphics) is protected by national and international laws and agreements.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company cannot guarantee the correctness, completeness, accuracy or quality of the Content.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Please note that user Content represents the views of the individual use and does not represent a statement, opinion recommendation or rating by the Company.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Any kind of copying, duplication, distribution, commercial exploitation, modification, adding and/or deletion is prohibited, including the integration of any content on external websites, for example through interlinks, deep links, or frames.
          </li>
        </ul>
        <h3 className="font-poppins my-4">PRIVACY POLICY
        </h3>
        <div className="font-poppins">Data protection is an important subject to Company. Any personal data, which you provide by using the Website, is used in accordance with applicable data protection regulations and our privacy policy.</div>
        <h3 className="font-poppins my-4">MODIFICATION OF TERMS OF USE
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> The Company reserves the right to modify or supplement these terms of use for regulatory, legal, technical or any other reason with future effect by placing an advance notice on the Website. The notice will include the date of planned change and the consequences for users.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Registered users of the Company will get additional information about planned change of terms of use via e-mail/sms.
          </li>
        </ul>
        <h3 className="font-poppins my-4">GOVERNING LAW AND FORUM
        </h3>
        <div className="font-poppins">These terms and conditions are governed by and shall be construed in accordance with the laws of the Republic of India and any dispute shall exclusively be subject to the jurisdiction of the appropriate Courts situated at Delhi, India</div>
        <h3 className="font-poppins my-4">SEVERABILITY CLAUSE
        </h3>
        <div className="font-poppins">If any part of these terms is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remaining provisions of these terms of use shall continue in effect.</div>
        <h3 className="font-poppins fw-bold my-4">PAYMENTS
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> WWW.THEMAARS.ORG (MCS) offers you multiple payment methods. Whatever your online mode of payment, you can rest assured that MCS trusted payment gateway partners use secure encryption technology to keep your transaction details confidential at all times.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> You may use Internet Banking, and Wallet to make your purchase. MCS also accepts payments made using Visa, MasterCard, Maestro and American Express credit/debit cards.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> There are NO hidden charges when you make a purchase on MCS. The prices listed for all the items are final and all-inclusive. The price you see on the product page is exactly what you pay.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> We accept payments made by credit/debit cards issued in India and abroad. 
          </li>
        </ul>
        <h3 className="font-poppins my-4">CREDIT CARDS
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> We accept payments made using Visa, MasterCard and American Express credit cards.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> To pay using your credit card at checkout, you will need your card number, expiry date, three-digit CVV number (found on the backside of your card). 3D secure password, mobile for One Time Password (OTP)
          </li>
        </ul>
        <h3 className="font-poppins my-4">DEBIT CARDS
        </h3>
        <ul className="list-style-none p-0 font-tiltN">
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> We accept payments made using Visa, MasterCard, RuPay card and Maestro debit cards.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> To pay using your debit card at checkout, you will need your card number, expiry date (optional for Maestro cards), three-digit CVV number (optional for Maestro cards). You will then be redirected to your bank's secure page for entering your online password (issued by your bank) to complete the payment, mobile for One Time Password (OTP)
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> Your online transaction on MCS is secure with the highest levels of transaction security currently available on the Internet. MCS uses 256-bit encryption technology to protect your card information while securely transmitting it to the respective banks for payment processing.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> All credit card and debit card payments on MCS are processed through secure and trusted payment gateways managed by leading banks. Banks now use the 3D secure password service for online transactions, providing an additional layer of security through identity verification.
          </li>
          <li>
            <b className="fz-24px green-arrow">&#8250;</b> MCS offers you the convenience of using your bank's Internet Banking service to make a payment towards your order. With this you can directly transfer funds from your bank account, while conducting a highly secure transaction.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TermsOfUse;
