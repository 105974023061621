import React, { useEffect } from "react";
import Slider from "../Components/Home/Slider";
import ExaminatorPara from "../Components/Home/ExaminatorPara";
import Services from "../Components/Home/Services";

function Home({ showLoader, setShowLoader }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Navbar /> */}
      <Slider showLoader={showLoader} />
      <ExaminatorPara showLoader={showLoader} setLoader={setShowLoader} />
      <Services showLoader={showLoader} />
    </>
  );
}

export default Home;
