import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import banner1 from "../../Resources/banner-1.svg";
import banner2 from "../../Resources/banner-2.svg";

function Slider() {
  const [index, setIndex] = useState(0);
  const [marginTop, setMarginTop] = useState("0");
  const slides = [banner1, banner2];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setMarginTop("6rem");
    } else {
      setMarginTop("0");
    }
  };

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      setIndex((index + 1) % slides.length);
    }, 3000);

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(autoplayInterval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [index, slides.length]);

  return (
    <div className="mt-4r">
      <div className="slider-fade-in">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          {slides.map((slide, idx) => (
            <Carousel.Item key={idx}>
            <img
              className="d-block w-100"
              src={slide}
              alt={`Slide ${idx + 1}`}
            />
          </Carousel.Item>  
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Slider;
