import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import logo from "../Resources/footer_logo.png";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

function CustomNavbar() {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    const checkHomepage = () => {
      if (window.location.pathname === "/") {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    checkHomepage(); // Check on initial load

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarClasses = `navbar ${isFixed ? "fixed-top" : "fixed-top"}`;

  return (
    <Navbar variant="light" expand="lg" className={navbarClasses}>
      <div className="w-80p d-flex m-auto ">
        <div className="d-flex">
          <Navbar.Brand as={NavLink} to="/" className="p-2">
            <img src={logo} alt="Logo" className="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
        <Navbar.Collapse id="basic-navbar-nav" className="nav-align justify-content-end">
          <Nav className="mr-auto">
            <Nav.Link as={NavLink} to="/" className="nav-item a-align-center">
              Home
            </Nav.Link>
            <NavDropdown title="Products" id="dropdown1" className="nav-item">
              <NavDropdown.Item as={NavLink} to="/examinator">
                Examinator
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Services" id="dropdown2" className="nav-item">
              <NavDropdown.Item as={NavLink} to="/software-development">
                Software Development
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/app-development">
                App Development
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={NavLink}
              to="/contact-us"
              className="nav-item a-align-center"
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default CustomNavbar;
