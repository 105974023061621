import React from "react";
import logo from "../Resources/footer_logo.png";
import mail from "../Resources/mail.png";
import mail_logo from "../Resources/mail_logo.png";
import globe_logo from "../Resources/globe.png";
import { NavLink } from "react-router-dom";

function Footer({ showLoader }) {
  return showLoader ? null : (
    <div>
      <footer
        id="Footer"
        className="page-footer mt-4 font-small stylish-color-dark pt-4"
      >
        <div className="container text-center text-md-left">
          <div className="row">
            <div className="col-md-2 mx-auto d-flex align-items-center">
              <div className="d-flex flex-column">
                <h5 className="text-uppercase font-weight-bold mt-3 mb-4">
                  <img src={logo} className="footer-logo" />
                </h5>
                {/* <p className="opacity-half font-tiltN">
                  Here you can use rows and columns to organize your footer
                  content. Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit.
                </p> */}
              </div>
            </div>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-2 col-lg-2 col-xl-2 w-auto-imp mx-auto d-flex align-items-center">
              <div className="d-flex links">
                <div>
                  <p>
                    <NavLink to={"/examinator"}>
                      <a href="#!" className="font-tiltN">
                        Examinator
                      </a>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to={"/software-development"}>
                      <a href="#!" className="font-tiltN">
                        Software Development
                      </a>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to={"/app-development"}>
                      <a href="#!" className="font-tiltN">
                        App Development
                      </a>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to={"/contact-us"}>
                      <a href="#!" className="font-tiltN">
                        Contact Us
                      </a>
                    </NavLink>
                  </p>
                </div>
                <div>
                  <p>
                    <NavLink to={"/privacy-policy"}>
                      <a href="#!" className="font-tiltN">
                        Privacy Policy
                      </a>
                    </NavLink>
                  </p>
                  <p>
                    <NavLink to={"/terms-and-conditions"}>
                      <a href="#!" className="font-tiltN">
                        Terms And Conditions
                      </a>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
            <hr className="clearfix w-100 d-md-none" />
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <p className="text-align-left">
                <i className="fas fa-home mr-3"></i> <b>Head Office</b> <br />
                Second Floor, 28, Sector-C,
                <br />
                Pocket-4, Sushant Golf City,
                <br />
                Lucknow, U.P. 226030
                <br />
                India.
              </p>
              <p className="d-flex align-items-center">
                <img src={mail_logo} className="footer-icon" />
                <img src={mail} className="footer-icon" />
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
