import React from "react";

function ServiceCard({ imgSide, img, textArray, heading, w80 }) {
  const imgClassName = imgSide === "left" ? "resp-w-100p" : "resp-w-100p";
  
  return (
    <div className={`service-card d-flex my-2r justify-content-evenly mb-3r mt-1r`} style={{ width: w80 ? "80%" : "70vw" }}>
      {imgSide==="left"?<div className={`d-flex align-item-center justify-content-around ${imgClassName}`}>
        <img src={img} className="img-w100" data-aos="fade-up-right" />
      </div>:""}
      <div className="services-text-container" data-aos="zoom-in">
        <h2 className={`orange-color-main resp-fz-20px resp-text-center font-poppins fw-bold resp-mt-1r ${imgSide === 'right' ? 'mb-1r' : 'my-1r'}`}>{heading}</h2>
        {textArray.map((text) => (
          <li className={`services-list-item font-tiltN ${imgSide === 'right' ? '' : 'color-white-dm'}`} key={text}>{text}</li>
        ))}
      </div>
      {imgSide==="right"?<div className={`d-flex align-item-center justify-content-end resp-w-100p resp-justify-center ${imgClassName}`}>
        <img src={img} className="img-w100" data-aos="fade-up-left"/>
      </div>:""}
    </div>
  );
}

export default ServiceCard;
