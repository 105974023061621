import React from "react";
import ServicesCard from "./ServiceCard";
import mobile_development from "../../Resources/mobile_dev.svg";
import software from "../../Resources/software.png";
import college from "../../Resources/college.png";

function Services({ showLoader }) {
  return showLoader ? null : (
    <div
      className="services d-flex flex-column my-2r resp-py-0 py-2r resp-mt-2r mt-4"
      id="services"
    >
      <div className="top-text text-center d-flex flex-column align-itm-center justify-cont-center mb-1r">
        {/* <div className="text-sm orange-color-main mb-1r">
        24/7 Electrician Services – Safe and Efficient
      </div> */}
        <div
          className={`heading-big resp-text-lg font-eagleB blue-color-main`}
        data-aos="fade-up">
          Our Services
        </div>
      </div>
      <ServicesCard
        imgSide={"left"}
        textArray={[
            "iOS and Android App Development",
            "Web App Development",
            "UX/UI Design",
            "App Testing and Quality Assurance",
            "App Maintenance and Updates"
          ]}
        img={mobile_development}
        heading={"App Development"}
      />
      <ServicesCard
        imgSide={"right"}
        textArray={[
            "Custom Software Development",
            "Web Application Development",
            "Enterprise Software Solutions",
            "Software Testing and Quality Assurance",
            "Software Maintenance and Support"
          ]}
        img={software}
        heading={"Software Development"}
      />
      <ServicesCard
        imgSide={"left"}
        textArray={[
          "User friendly and easy user interface.",
          "Automated result processing.",
          "Results in downloadable excel sheets.",
          "Multiple languages supported.",
          "Question import feature.",
          "Exam creation from templates.",
          "Secure software for center based exams.",
          "Center management.",
          "Admit card generation and emailing.",
        ]}
        img={college}
        heading={"Features"}
      />
    </div>
  );
}

export default Services;
