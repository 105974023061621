import React from "react";
import PageInfo from "./PageInfo";
import TextCard from "./TextCard";
import userFriendly from "../Resources/user_friendly.png";
import automatic_result from "../Resources/automatic_result.png";
import admit_card from "../Resources/admit_card.png";
import management from "../Resources/management.png";
import save_time from "../Resources/save_time.png";
import tests from "../Resources/tests.png";
import secure from "../Resources/secure.png";
import languages from "../Resources/languages.png";
import importing from "../Resources/importing.png";

function Features() {
  return (
    <div className="mt-6r">
      <PageInfo title={"Home / Features"} />
      <TextCard
        img={userFriendly}
        imgSide={"right"}
        heading={"User Friendly And Easy To Use Platform"}
        text={`Examinator's easy to use user interface allows to create exams quickly. Examinator offers the best
online exam software experience that automates the entire examination process. After creating master
details, it takes no time to create and publish exams. Examinator supports to create questions in
different regional languages. Whether it is complete online exam or centre-based offline test
environment, Examinator's high concurrency assessment system make the entire process a breeze.`}
        last={false}
      />
      <TextCard
        img={automatic_result}
        imgSide={"left"}
        heading={"Auto Result Generation"}
        text={`Result generation is entirely automated. Exam creator can control when to generate result, same day or
        on some specific date. Results can be downloaded as Excel sheets.`}
        last={false}
      />
      <TextCard
        img={languages}
        imgSide={"right"}
        heading={"Dual Language Display"}
        text={`Exclusive feature of Examinator's exam user interface allows to display same question if two different
        languages and optionally allow examinee to change second language to another from available
        languages. Exam creator can control the display of multiple languages, enables or disables option to
        change languages by examinee.`}
        last={false}
      />
      <TextCard
        img={importing}
        imgSide={"left"}
        heading={"Import Questions"}
        text={`Whether it is easy to create questions in web-panel. But Examinator also support to import questions
        from temlated Libre/Open Office documents along with equations and images. Questions can be
        prepared in Microsoft Word too, that can be later easily saved to Libre/Open Office document for
        import.`}
        last={false}
      />
      <TextCard
        img={save_time}
        imgSide={"right"}
        heading={"Quick exam generation form templates"}
        text={`Forget the days when you have to remember the pattern of different exams. Examinator allows you to
        create as many templates for different exam patterns. Simply set how many questions to pick from
        which subject/topic, and when creating exam from template, Examinator will auto pick questions as per
        template configuration and create exam.`}
        last={false}
      />
      <TextCard
        img={secure}
        imgSide={"left"}
        heading={"Secure Software for Centers"}
        text={`Whether you are taking exam for 1M candidates or 10M, our secure center exam software can handle
        all the load. Our automated center software installation make the process very secure, even we do not
        know the credentials of databases used in centers. Internet connectivity not required for opening exams
        locally. Internet connectivity is only required for downloading exam once before the exam and uploading
        data to servers after examination.`}
        last={false}
      />
      <TextCard
        img={management}
        imgSide={"right"}
        heading={"Center Management"}
        text={`For offline examination, Examinator's powerful Center Management feature allows to create different
        centers and helps to assign users to different centers.`}
        last={false}
      />
      <TextCard
        img={admit_card}
        imgSide={"left"}
        heading={"Admit Card generation and mailing"}
        text={`For center based examinations, Examinator allows to create and email Admit Cards to users to there
        registered email address.`}
        last={false}
      />
      <TextCard
        img={tests}
        imgSide={"right"}
        heading={"Merge Result of Multiple Tests"}
        text={`Exams with huge number of candidates are normally scheduled in multiple shifts and may span multiple
        days, for such exams we usually create different sets of that exam. Examinator allows to merge result
        of different sets into a single rank list.`}
        last={true}
      />
    </div>
  );
}

export default Features;
