import React, { useState, useEffect } from "react";
import TextCard from "../Components/TextCard";
import app_dev from "../Resources/mobile_dev.svg"
import PageInfo from "../Components/PageInfo";

function AppDev() {
  const [marginTop, setMarginTop] = useState("0");
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setMarginTop("6rem");
    } else {
      setMarginTop("0");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="mt-6r">
      <PageInfo title={"Home / App Development"}/>
      <TextCard
        font={"TN"}
        img={app_dev}
        heading={"Maars: Pioneering Excellence in App Development"}
        text={`In the ever-changing digital landscape, businesses are constantly seeking innovative solutions to gain a competitive edge. Maars, a prominent name in the world of app development, offers a wide range of cutting-edge solutions to meet the evolving needs of businesses globally.<br/><br/>

        <b>Our Company:</b><br/><br/>
        
        Maars, with a reputation for excellence and an unwavering commitment to pushing the boundaries of technology, has firmly established itself as a trusted partner for businesses of all sizes. Our team of skilled and experienced professionals is dedicated to providing tailored app development services that empower our clients to achieve their goals.<br/><br/>
        
        <b>Our Services:</b><br/><br/>
        
        <b>Custom App Development:</b> At Maars, we specialize in creating customized app solutions that align with your specific business objectives. We meticulously design, develop, and implement apps that streamline processes, enhance productivity, and boost your bottom line.<br/>
        
        <b>Mobile App Development:</b> In a world driven by mobile technology, Maars excels in crafting mobile apps that are not only visually captivating but also functionally robust. Our mobile solutions offer seamless user experiences, resulting in increased engagement and user satisfaction.<br/>
        
        <b>Cross-Platform Development:</b> We provide cross-platform app development services, ensuring that your brand reaches customers on various devices and platforms.<br/>
        
        <b>App Optimization:</b> Maars is committed to delivering high-performance apps. We offer app optimization services to ensure your apps run efficiently and deliver a smooth user experience.<br/>
        
        <b>Maintenance and Support:</b> Beyond development, we provide ongoing app maintenance and support to keep your apps up-to-date and trouble-free. Our dedicated support team is always ready to assist you with any issues.<br/>
        
        <b>Why Choose Maars:</b><br/><br/>
        
        <b>Expertise:</b> Our team of skilled developers and tech experts has an in-depth understanding of the latest technologies and industry best practices.<br/>
        
        <b>Customization:</b> We recognize that each business is unique, and we tailor our app development services to match your specific requirements.<br/>
        
        <b>Quality Assurance:</b> Maars is committed to delivering high-quality, error-free app solutions that exceed your expectations.<br/>
        
        <b>Timely Delivery:</b> We value your time, and our projects are executed with precision and delivered on schedule.<br/><br/>
        
        Partner with Maars today to embark on a journey of technological innovation and business growth. We are your gateway to reliable and innovative app development services that will propel your business to new heights.`}
        imgSide={"right"}
        roundedCorner={true}
      />
    </div>
  );
}

export default AppDev;
