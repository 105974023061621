import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import exam_img from "../../Resources/exam.jpg"

function ExaminatorPara({ showLoader, setLoader }) {
  useEffect(() => {
    // When the component mounts, show the loader.
    setLoader(true);

    // Simulate loading for 2 seconds.
    setTimeout(() => {
      // After 2 seconds, hide the loader.
      setLoader(false);
    }, 2000);
  }, [setLoader]);

  if (showLoader) {
    // Display the loader while showLoader is true.
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }


  return (
    <div className="mt-4">
      <div className="section">
        <div className="paras">
          <p className="sectionTag text-big">
            Revolutionize Assessment with Examinator
          </p>
          <p className="sectionSubTag text-small">
            Examinator is an on-demand, secure and high concurrency online
            assessment system. It is a best companion for institutions,
            educational centers, Corporate and Government organizations for
            assessing students, employees, or job aspirants.
            <br /> Examinator's innovative online exam system helps create
            web-based and computer-based online and offline exams. Whether it is
            a complete online exam or center-based offline test environment.
            <NavLink to={"/examinator"}>
              <button className="btn btn-link">Read More...</button>
            </NavLink>
          </p>
        </div>
        <div className="thumbnail">
          <img
            src={exam_img}
            alt="gym image"
            className="imgFluid"
          />
        </div>
      </div>
    </div>
  );
}

export default ExaminatorPara;
