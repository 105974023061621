import React, { useState, useEffect } from "react";
import TextCard from "../Components/TextCard";
import study from "../Resources/eStudy.png";
import PageInfo from "../Components/PageInfo";
import college from "../Resources/college.png";
import ServiceCard from "../Components/Home/ServiceCard";

function Examinator() {
  const [marginTop, setMarginTop] = useState("0");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setMarginTop("6rem");
    } else {
      setMarginTop("0");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="mt-4r">
      <PageInfo title={"Home / Examinator"} />
      <TextCard
        img={study}
        heading={"Examinator"}
        text={`Examinator is a cutting-edge examination system that seamlessly combines online and center-based testing to cater to the diverse assessment needs of institutions, educational centers, corporations, and government organizations. This versatile platform offers a comprehensive solution for user management, encompassing user registration and efficient center allocation.<br/><br/>

        With Examinator, institutions can conduct assessments with ease and confidence, thanks to its secure and high-concurrency infrastructure. It empowers educational institutions to evaluate students' knowledge, corporations to assess employees' skills, and government organizations to screen job applicants effectively.<br/><br/>
        
        One of Examinator's standout features is its ability to create a wide range of assessments, including web-based and computer-based exams. It accommodates both online and offline testing, making it a flexible and adaptable tool for a variety of examination scenarios. This flexibility ensures that regardless of the preferences or constraints of your organization, Examinator has a solution to meet your assessment needs.<br/><br/>
        
        Examinator is designed to streamline the examination process, enhance security, and provide a user-friendly experience for both administrators and test-takers. It's the go-to solution for those seeking an efficient and reliable assessment system to make informed decisions about their students, employees, or job candidates.
        `}
        imgSide={"right"}
        roundedCorner={true}
      />
      <div className="d-flex justify-content-center align-items-center">
      <ServiceCard
        imgSide={"left"}
        textArray={[
          "User friendly and easy user interface.",
          "Automated result processing.",
          "Results in downloadable excel sheets.",
          "Multiple languages supported.",
          "Question import feature.",
          "Exam creation from templates.",
          "Secure software for center based exams.",
          "Center management.",
          "Admit card generation and emailing.",
        ]}
        img={college}
        heading={"Features"}
        w80={true}
      />
      </div>
    </div>
  );
}

export default Examinator;
