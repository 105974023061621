import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home";
import Examinator from "./Pages//Examinator";
import { useState } from "react";
import Footer from "./Components/Footer";
import CustomNavbar from "./Components/Navbar";
import Features from "./Components/Features";
import SoftDev from "./Pages/SoftDev";
import ContactUs from "./Pages/ContactUs";
import AppDev from "./Pages/AppDev";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import QuestionPaperGen from "./Pages/FooterLinkPages/QuestionPaperGen";
// import Institutions from "./Pages/FooterLinkPages/Institutions";
// import SchoolsOlympiads from "./Pages/FooterLinkPages/SchoolsOlympiads";
// import CollegesUniversities from "./Pages/FooterLinkPages/CollegesUniversities";
// import Govt_Psus from "./Pages/FooterLinkPages/Govt_Psus";
// import RecruitmentAppraisals from "./Pages/FooterLinkPages/RecruitmentAppraisals";
import PrivacyPolicy from "./Pages/FooterLinkPages/PrivacyPolicy";
import TermsOfUse from "./Pages/FooterLinkPages/TermsOfUse";

function App() {
  const [showLoader, setShowLoader] = useState(false); // Control whether to show the loader or slider
  return (
    <div className="App">
      <BrowserRouter>
        <CustomNavbar />
        <div className="App" id="home">
          <Routes>
            <Route
              path="/"
              element={
                <Home showLoader={showLoader} setShowLoader={setShowLoader} />
              }
            />
            <Route path="/examinator" element={<Examinator />} />
            <Route path="/features" element={<Features />} />
            <Route path="/software-development" element={<SoftDev />} />
            <Route path="/app-development" element={<AppDev />} />
            <Route path="/contact-us" element={<ContactUs />} />
            {/* <Route path="/question-paper-generator" element={<QuestionPaperGen />} />
            <Route path="/institutional-solutions" element={<Institutions />} />
            <Route path="/schools-and-olympiads" element={<SchoolsOlympiads />} />
            <Route path="/colleges-and-universities" element={<CollegesUniversities />} />
            <Route path="/government-and-psus" element={<Govt_Psus />} />
            <Route path="/recruitment-and-appraisal" element={<RecruitmentAppraisals />} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsOfUse />} />
          </Routes>
        </div>
        <Footer showLoader={showLoader} />
      </BrowserRouter>
    </div>
  );
}

export default App;
